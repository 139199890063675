import {
    AdditionalClientContact,
    Client,
    ClientId,
    Community,
    CommunityId,
    CommunityRelationshipType,
    ContactEntityId,
    ContactId,
    FileId,
    IAnswer,
    IClient,
    ICommunity,
    IFile,
    InternalId,
    IQuestion,
    ITenant,
    Maybe,
    mostlyEmptyQuestion,
    parseEntityId,
    PermissionMapping,
    Question,
    QuestionId,
    TenantId,
    User,
    UserId,
} from "@sp-crm/core";
import { ClientSearchSort } from "generated/graphql";
import { QueryClient } from "react-query";
import { Action, Dispatch } from "redux";
import { push } from "util/history";
import "whatwg-fetch";
import * as browser from "../../util/browser";
import { setFavicon } from "../../util/browser";
import { setErrorTrackingContext } from "../../util/error-tracking";
import * as http from "../../util/http";
import { logoutEvent, tenantIdEvent, userIdEvent } from "../../util/user-events";
import { invalidateCommunityQueryCache } from "./communities";

export enum Actions {
    UPDATE_SIZE,
    HIDE_MENU,
    SHOW_MENU,
    COUNT_ITEMS_IN_QUESTION_CATEGORY,
    CLEAR_TENANT_SAVE_NOTIFICATION,
    CLEAR_USER_SAVE_NOTIFICATION,
    ADD_COMMUNITY_TO_CLIENT_FINISH,
    ADD_COMMUNITY_TO_CLIENT_START,
    CLIENTS_LIST_SELECT_USER,
    CLIENTS_LIST_SELECT_REFERRAL_USER,
    CLIENT_SEARCH_TEXT,
    CLIENT_SEARCH_TYPE,
    COMMUNITY_SEARCH_CLEAR,
    COMMUNITY_SEARCH_INITIALIZE,
    COMMUNITY_SEARCH_NEXT_PAGE,
    COMMUNITY_SEARCH_PREV_PAGE,
    COMMUNITY_SEARCH_GO_TO_PAGE,
    COMMUNITY_SEARCH_SET_PAGE_SIZE,
    COMMUNITY_SEARCH_UPDATE_SEARCH,
    COMMUNITY_SEARCH_MAP_MOVE,
    COMMUNITY_SEARCH_MAP_RESET,
    DASHBOARD_SELECT_USER,
    DASHBOARD_SORT,
    EULA_TRANSITION,
    EXCLUDE_COMMUNITY_FROM_CLIENT_START,
    EXCLUDE_COMMUNITY_FROM_CLIENT_FINISH,
    UNEXCLUDE_COMMUNITY_FROM_CLIENT_START,
    UNEXCLUDE_COMMUNITY_FROM_CLIENT_FINISH,
    ADD_NO_COMPARE_FROM_CLIENT_START,
    ADD_NO_COMPARE_FROM_CLIENT_FINISH,
    REMOVE_NO_COMPARE_FROM_CLIENT_START,
    REMOVE_NO_COMPARE_FROM_CLIENT_FINISH,
    GEOCODE_FINISH,
    GEOCODE_START,
    LOAD_ALL_CLIENTS_FINISH,
    LOAD_ALL_CLIENTS_START,
    LOAD_CLIENTS_FOR_COMMUNITY_FINISH,
    LOAD_CLIENTS_FOR_COMMUNITY_START,
    LOAD_COMMUNITIES_PAGE_FINISH,
    LOAD_ALL_COMMUNITIES_FINISH,
    LOAD_COMMUNITIES_PAGE_START,
    LOAD_COMMUNITIES_BY_CLIENT_START,
    LOAD_COMMUNITIES_BY_CLIENT_FINISH,
    LOAD_ALL_TASKS_END,
    LOAD_ALL_TASKS_START,
    LOAD_ALL_TOURS_END,
    LOAD_ALL_TOURS_START,
    LOAD_ALL_USERS_END,
    LOAD_ALL_USERS_START,
    LOAD_CLIENT_FINISH,
    LOAD_CLIENT_START,
    LOAD_COMMUNITY_FINISH,
    LOAD_COMMUNITY_START,
    LOAD_QUESTION_START,
    LOAD_QUESTION_FINISH,
    LOAD_SETTINGS_FINISH,
    LOAD_SETTINGS_START,
    LOAD_TENANT_START,
    LOAD_TENANT_FINISH,
    LOGIN_FAIL,
    LOGIN_FINISH,
    LOGIN_START,
    LOGOUT,
    REMOVE_COMMUNITY_FROM_CLIENT_FINISH,
    REMOVE_COMMUNITY_FROM_CLIENT_START,
    SAVE_NEW_CLIENT_FINISH,
    SAVE_CLIENT_NEW_CONTACT_PERSON_START,
    SAVE_CLIENT_NEW_CONTACT_PERSON_FINISH,
    UPDATE_CLIENT_CONTACT_PERSON_START,
    UPDATE_CLIENT_CONTACT_PERSON_FINISH,
    DELETE_CLIENT_CONTACT_PERSON_START,
    DELETE_CLIENT_CONTACT_PERSON_FINISH,
    UPDATE_CLIENT_COMMUNITY_ORDER,
    SAVE_NEW_QUESTION_START,
    SAVE_NEW_QUESTION_FINISH,
    SAVE_NEW_QUESTION_FAIL,
    SAVE_NEW_CLIENT_START,
    SAVE_NEW_COMMUNITY_CONTACT_FINISH,
    SAVE_NEW_COMMUNITY_CONTACT_START,
    SAVE_NEW_COMMUNITY_FINISH,
    SAVE_NEW_COMMUNITY_PHOTOS_START,
    SAVE_NEW_COMMUNITY_PHOTOS_FINISH,
    SAVE_NEW_EVERGREEN_LISTING_PHOTOS_START,
    SAVE_NEW_EVERGREEN_LISTING_PHOTOS_FINISH,
    SAVE_NEW_COMMUNITY_START,
    SAVE_NEW_TASK_FINISH,
    SAVE_NEW_TASK_START,
    SAVE_NEW_TOUR_FINISH,
    SAVE_NEW_TOUR_START,
    SAVE_SETTINGS_FINISH,
    SAVE_SETTINGS_START,
    REPORT_PARAMETER_INBOUND_REFERRAL_START_DATE_UPDATE,
    REPORT_PARAMETER_INBOUND_REFERRAL_END_DATE_UPDATE,
    REPORT_PARAMETER_CLIENT_PLACEMENT_START_DATE_UPDATE,
    REPORT_PARAMETER_CLIENT_PLACEMENT_END_DATE_UPDATE,
    REPORT_PARAMETER_CLIENT_PLACEMENT_SHOW_PER_USER_UPDATE,
    REPORT_PARAMETER_SUMMARY_START_DATE_UPDATE,
    REPORT_PARAMETER_SUMMARY_END_DATE_UPDATE,
    REPORT_PARAMETER_CUSTOM_NEW_REPORT_INPUT_UPDATE,
    DELETE_SETTING_START,
    DELETE_SETTING_FINISH,
    TICK,
    HEARTBEAT,
    MODIFY_UNSAVED_QUESTION,
    UPDATE_CLIENT_FINISH,
    UPDATE_CLIENT_START,
    UPDATE_COMMUNITY_CONTACT_FINISH,
    UPDATE_COMMUNITY_CONTACT_START,
    DELETE_COMMUNITY_CONTACT_START,
    DELETE_COMMUNITY_CONTACT_FINISH,
    UPDATE_COMMUNITY_FINISH,
    UPDATE_COMMUNITY_PHOTOS_START,
    UPDATE_COMMUNITY_PHOTOS_FINISH,
    DELETE_COMMUNITY_PHOTOS_START,
    DELETE_COMMUNITY_PHOTOS_FINISH,
    UPDATE_COMMUNITY_START,
    UPDATE_TASK_FINISH,
    UPDATE_TASK_START,
    UPDATE_TENANT_FINISH,
    UPDATE_TENANT_START,
    UPDATE_TOUR_FINISH,
    UPDATE_TOUR_START,
    UPDATE_USER_FINISH,
    UPDATE_USER_START,
    UPDATE_SETTINGS_START,
    UPDATE_SETTINGS_FINISH,
    UPDATE_SETTINGS_FAIL,
    SELECT_CONTACT,
    LOAD_SINGLE_QUESTION,
    DESTROY_SINGLE_QUESTION,
    DELETE_COMMUNITY_SELECTED_CONTACT,
    DELETE_TASK,
    //PDF
    DOWNLOAD_COMMUNITY_COMPARISON_PDF_START,
    DOWNLOAD_COMMUNITY_COMPARISON_PDF_FINISH,
    DOWNLOAD_COMMUNITY_COMPARISON_PDF_ERROR,
    DOWNLOAD_CLIENT_REFERRAL_PDF_START,
    DOWNLOAD_CLIENT_REFERRAL_PDF_FINISH,
    DOWNLOAD_CLIENT_REFERRAL_PDF_ERROR,
    // email form
    EMAIL_FORM_INIT,
    EMAIL_FORM_SUBMIT,
    EMAIL_FORM_ERROR,
    EMAIL_FORM_SUCCESS,
    EMAIL_FORM_VALUE_CHANGE,
    EMAIL_FORM_VALUE_CLEAR,
    EMAIL_FORM_TEMPLATE_INIT,
    RESET_PASSWORD_START,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILED,
    SET_PASSWORD_START,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAILED,
    LOAD_ALL_EMAILTEMPLATES_START,
    LOAD_ALL_EMAILTEMPLATES_FINISH,
    SAVE_NEW_EMAILTEMPLATE_START,
    SAVE_NEW_EMAILTEMPLATE_FINISH,
    UPDATE_EMAILTEMPLATE_START,
    UPDATE_EMAILTEMPLATE_FINISH,
    DELETE_EMAILTEMPLATE_START,
    DELETE_EMAILTEMPLATE_FINISH,
    TASK_TOPLEVEL_SELECT_USER,
    TASK_TOPLEVEL_UPDATE_QUERY,
    TASK_TOPLEVEL_SELECT_STATE,
    TASK_TOPLEVEL_SELECT_ENTITY,
    TASK_TOPLEVEL_SELECT_TYPE,
    DUMMY_REQUEST_START,
    DUMMY_REQUEST_FINISH,
    REQUEST_FAIL,
    REQUEST_FAIL_ACKNOWLEDGE,
    REQUEST_UNAUTHORIZED,
    REQUEST_UNAUTHORIZED_ACKNOWLEDGE,
    // FILES
    LOAD_FILES_START,
    LOAD_FILES_FINISH,
    UPLOAD_FILE,
    UPDATE_FILE_START,
    UPDATE_FILE_FINISH,
    CLEAR_FILE_CACHE,
    // REGIONS
    SELECT_REGION,
    LOAD_PERMISSIONS_START,
    LOAD_PERMISSIONS_FINISH,
    REACTIVATE_CLIENT_START,
    REACTIVATE_CLIENT_FINISH,
    LOAD_REFERRALS_FINISH,
    LOAD_REFERRALS_START,
    UPDATE_REFERRAL_FINISH,
    UPDATE_REFERRAL_START,
    CREATE_REFERRAL_FINISH,
    CREATE_REFERRAL_START,
    DELETE_REFERRAL_FINISH,
    DELETE_REFERRAL_START,
    OPEN_REFERRAL_EDIT,
    // EMAILS SENT
    LOAD_EMAILS_SENT_FINISH,
    LOAD_EMAILS_SENT_START,
    OPEN_EMAIL_PREVIEW,
    CLOSE_EMAIL_PREVIEW,
    LOAD_GUEST_COMMUNITY_COMPARISON_START,
    LOAD_GUEST_COMMUNITY_COMPARISON_FINISH,
    GLOBAL_SUCCESS_NOTIFY,
    GLOBAL_SUCCESS_CLEAR,
}

export function getAndDispatchActionsWithAPIPayload<T>(
    dispatch: Dispatch<Action>,
    method: string,
    path: string,
    startType: Actions,
    endType: Actions,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    callback: (v: T) => any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    payload?: any,
): Promise<T> {
    dispatch({ type: Actions[startType] });
    const requestDescription = http.newRequestDescription();
    return (
        http
            .send(requestDescription, method, path, payload)
            .then(r => r.data)
            .then((responseData: T) => {
                const payload = callback(responseData);
                dispatch({
                    ...payload,
                    type: Actions[endType],
                });
                return responseData;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .catch(r => http.errorHandler(r, requestDescription, dispatch) as any)
    );
}

export function navigate(path: string): void {
    push(path);
}

export function clientLogout(dispatch: Dispatch<Action>): void {
    browser.clearCredentials();
    logoutEvent();
    dispatch({ type: Actions[Actions.LOGOUT] });
    push("/login");
}

export function logout(dispatch: Dispatch<Action>): void {
    http.send(http.newRequestDescription(), "DELETE", "/api/session").then(() => {
        clientLogout(dispatch);
    });
}

export function signEula(
    payload: browser.ILoginResponse,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
) {
    browser.handleLogin(payload);
    const userRecord = { signedEula: new Date() };
    updateUser(userRecord as User, dispatch);
    loadAll(queryClient, dispatch);
    dispatch({ type: Actions[Actions.LOGIN_FINISH], user: payload });
    push("/");
}

interface DashboardSortPayload {
    cardSort: ClientSearchSort;
}

export type DashboardSortAction = DashboardSortPayload & Action;

export function login(
    credentials: browser.LoginCredentials,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): void {
    dispatch({ type: Actions[Actions.LOGIN_START] });
    http.post(http.newRequestDescription(), "/api/session", credentials)
        .then(r => r.data)
        .then(r => {
            if (r.userRecord && r.userRecord.signedEula) {
                browser.handleLogin(r);
                dispatch({ type: Actions[Actions.LOGIN_FINISH], user: r });
                loadAll(queryClient, dispatch);
                push("/");
            } else {
                dispatch({
                    userPayload: r,
                    type: Actions[Actions.EULA_TRANSITION],
                });
                push("/license");
            }
        })
        .catch(e => {
            dispatch({
                type: Actions[Actions.LOGIN_FAIL],
                message:
                    typeof e?.response?.data?.err === "string"
                        ? e.response.data.err
                        : "An unexpected error happed on login. Try again later or contact support for help.",
            });
        });
}

export function saveNewCommunity(
    newCommunity: Community,
    dispatch: Dispatch<Action>,
): void {
    dispatch({ type: Actions[Actions.SAVE_NEW_COMMUNITY_START] });
    const requestDescription = http.newRequestDescription();
    http.post(requestDescription, "/api/communities", newCommunity)
        .then(r => r.data)
        .then((responseData: Community) => {
            dispatch({
                type: Actions[Actions.SAVE_NEW_COMMUNITY_FINISH],
                community: responseData,
            });
            return responseData;
        })
        .then((responseData: Community) => {
            push("/communities/show/" + responseData.id);
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateCommunityClientNotes(
    clientId: ClientId,
    communityId: CommunityId,
    noteType: "private" | "public",
    notes: string,
    dispatch: Dispatch<Action>,
): Promise<void | IClient> {
    dispatch({
        type: Actions[Actions.ADD_COMMUNITY_TO_CLIENT_START],
        communityId,
        clientId,
    });
    const requestDescription = http.newRequestDescription();

    return http
        .put(
            requestDescription,
            `/api/clients/${clientId}/communities/${communityId}/notes`,
            {
                notes,
                noteType,
            },
        )
        .then(r => r.data)
        .then((responseData: IClient) => {
            dispatch({
                type: Actions[Actions.ADD_COMMUNITY_TO_CLIENT_FINISH],
                client: responseData,
                when: new Date(),
            });

            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateCommunityClient(
    clientId: ClientId,
    communityId: CommunityId,
    relationship: CommunityRelationshipType,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({
        type: Actions[Actions.ADD_COMMUNITY_TO_CLIENT_START],
        communityId,
        clientId,
    });
    const requestDescription = http.newRequestDescription();

    return http
        .put(requestDescription, `/api/clients/${clientId}/communities/${communityId}`, {
            relationship: relationship,
        })
        .then(r => r.data)
        .then((responseData: IClient) => {
            dispatch({
                type: Actions[Actions.ADD_COMMUNITY_TO_CLIENT_FINISH],
                client: responseData,
                when: new Date(),
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function removeCommunityFromClient(
    clientId: ClientId,
    communityId: CommunityId,
    dispatch: Dispatch<Action>,
): Promise<IClient | void> {
    dispatch({
        type: Actions[Actions.REMOVE_COMMUNITY_FROM_CLIENT_START],
        communityId,
        clientId,
    });
    const requestDescription = http.newRequestDescription();
    return http
        .deleteReq(
            requestDescription,
            `/api/clients/${clientId}/communities/${communityId}`,
        )
        .then(r => r.data)
        .then((responseData: IClient) => {
            dispatch({
                type: Actions[Actions.REMOVE_COMMUNITY_FROM_CLIENT_FINISH],
                client: responseData,
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function addCommunityPhotos(
    communityId: CommunityId,
    photos: File[],
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({
        type: Actions[Actions.SAVE_NEW_COMMUNITY_PHOTOS_START],
        communityId: communityId,
    });

    const formData = new FormData();
    photos.forEach(photo => formData.append("photos", photo));

    const requestDescription = http.newRequestDescription();
    return http
        .post(requestDescription, `/api/communities/${communityId}/photos`, formData, {
            "Content-Type": "multipart/form-data",
        })
        .then(r => r.data)
        .then((responseData: { community: ICommunity }) => {
            dispatch({
                type: Actions[Actions.SAVE_NEW_COMMUNITY_PHOTOS_FINISH],
                community: responseData.community,
            });
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

function updateCommunityPhotoHelper(
    communityId: CommunityId,
    photoId: FileId,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    payload: any,
) {
    const requestDescription = http.newRequestDescription();
    return http
        .put(
            requestDescription,
            `/api/communities/${communityId}/photos/${photoId}`,
            payload,
        )
        .then(r => r.data)
        .then((responseData: { community: ICommunity }) => {
            dispatch({
                type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_FINISH],
                community: responseData.community,
            });
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateCommunityPhoto(
    communityId: CommunityId,
    photoId: FileId,
    newPhoto: File,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({
        type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_START],
        communityId: communityId,
    });

    const formData = new FormData();
    formData.append("photo", newPhoto);

    return updateCommunityPhotoHelper(communityId, photoId, dispatch, formData);
}

export function updateCommunityPhotoDescription(
    communityId: CommunityId,
    photoId: FileId,
    description: string,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({
        type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_START],
        communityId: communityId,
    });

    return updateCommunityPhotoHelper(communityId, photoId, dispatch, { description });
}

export function updateCommunityPhotos(
    communityId: CommunityId,
    photos: Partial<IFile>[],
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({
        type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_START],
        communityId: communityId,
    });

    const requestDescription = http.newRequestDescription();
    return http
        .put(requestDescription, `/api/communities/${communityId}/photos`, photos)
        .then(r => r.data)
        .then((responseData: { community: ICommunity }) => {
            dispatch({
                type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_FINISH],
                community: responseData.community,
            });
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function deleteCommunityPhoto(
    communityId: CommunityId,
    photoId: FileId,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({
        type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_START],
        communityId: communityId,
    });

    const requestDescription = http.newRequestDescription();
    return http
        .deleteReq(
            requestDescription,
            `/api/communities/${communityId}/photos/${photoId}`,
        )
        .then(r => r.data)
        .then((responseData: { community: ICommunity }) => {
            dispatch({
                type: Actions[Actions.UPDATE_COMMUNITY_PHOTOS_FINISH],
                community: responseData.community,
            });
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export async function addAdditionalClientPhysicianContact(
    clientId: ClientId,
    additionalClientId: InternalId,
    payload: Partial<AdditionalClientContact>,
    dispatch: Dispatch<Action>,
): Promise<{ client: IClient; newContactId: ContactId } | null> {
    dispatch({
        type: Actions[Actions.SAVE_CLIENT_NEW_CONTACT_PERSON_START],
        clientId: clientId,
    });
    const requestDescription = http.newRequestDescription();
    return http
        .post(
            requestDescription,
            `/api/clients/${clientId}/additionalClients/${additionalClientId}/physicianContacts`,
            payload,
        )
        .then(r => r.data)
        .then((responseData: { client: IClient; newContactId: ContactId }) => {
            dispatch({
                type: Actions[Actions.SAVE_CLIENT_NEW_CONTACT_PERSON_FINISH],
                client: responseData.client,
                newContactId: responseData.newContactId,
            });
            return responseData;
        })
        .catch(r => {
            http.errorHandler(r, requestDescription, dispatch);
            return null;
        });
}

export function updateAdditionalClientContactPerson(
    clientId: ClientId,
    additionalClientId: InternalId,
    contactId: ContactId,
    contact: AdditionalClientContact,
    dispatch: Dispatch<Action>,
): void {
    dispatch({
        type: Actions[Actions.UPDATE_CLIENT_CONTACT_PERSON_START],
        clientId,
        contactId,
        text: contact,
    });
    const payload = contact;
    const requestDescription = http.newRequestDescription();
    http.put(
        requestDescription,
        `/api/clients/${clientId}/additionalClients/${additionalClientId}/physicianContacts/${contactId}`,
        payload,
    )
        .then(r => r.data)
        .then((responseData: IClient) => {
            dispatch({
                type: Actions[Actions.UPDATE_CLIENT_CONTACT_PERSON_FINISH],
                client: responseData,
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function deleteAdditionalClientContactPerson(
    clientId: ClientId,
    additionalClientId: InternalId,
    contactId: ContactId,
    dispatch: Dispatch<Action>,
): void {
    dispatch({
        type: Actions[Actions.DELETE_CLIENT_CONTACT_PERSON_START],
        clientId,
        contactId,
    });
    const requestDescription = http.newRequestDescription();
    http.deleteReq(
        requestDescription,
        `/api/clients/${clientId}/additionalClients/${additionalClientId}/physicianContacts/${contactId}`,
    )
        .then(r => r.data)
        .then((responseData: IClient) => {
            dispatch({
                type: Actions[Actions.DELETE_CLIENT_CONTACT_PERSON_FINISH],
                client: responseData,
            });

            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateUser(user: User, dispatch: Dispatch<Action>): Promise<unknown> {
    dispatch({ type: Actions[Actions.UPDATE_USER_START] });
    const requestDescription = http.newRequestDescription();
    return http
        .put(requestDescription, `/api/user`, user)
        .then(r => r.data)
        .then((responseData: User) => {
            dispatch({
                type: Actions[Actions.UPDATE_USER_FINISH],
                user: responseData,
            });
            setTimeout(
                () =>
                    dispatch({
                        type: Actions[Actions.CLEAR_USER_SAVE_NOTIFICATION],
                    }),
                5000,
            );
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateTenant(tenant: ITenant, dispatch: Dispatch<Action>): void {
    getAndDispatchActionsWithAPIPayload(
        dispatch,
        "PUT",
        `/api/tenants/${tenant.tenantId}`,
        Actions.UPDATE_TENANT_START,
        Actions.UPDATE_TENANT_FINISH,
        (responseData: ITenant) => ({ tenant: responseData }),
        tenant,
    ).then(() => {
        setTimeout(
            () =>
                dispatch({
                    type: Actions[Actions.CLEAR_TENANT_SAVE_NOTIFICATION],
                }),
            5000,
        );
    });
}

export async function createQuestion(
    order: number,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void> {
    try {
        dispatch({ type: Actions[Actions.SAVE_NEW_QUESTION_START] });

        const question = mostlyEmptyQuestion(order).asJson;

        const requestDescription = http.newRequestDescription();
        const response: IQuestion = await http
            .post(requestDescription, "/api/questions", question)
            .then(r => r.data)
            .catch(r => http.errorHandler(r, requestDescription, dispatch));

        const q = Question.load(response);
        dispatch({
            type: Actions[Actions.UPDATE_SETTINGS_FINISH],
            question: q,
        });

        push(`/settings/agency/customize/cc/${q.id}`);
        refetchBridgeEntityTypes(queryClient);
    } catch (err) {
        dispatch({
            type: Actions[Actions.SAVE_NEW_QUESTION_FAIL],
            errorType: err.message.toLowerCase(),
        });
    }
}

export function updateSettings(
    question: IQuestion,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void | IQuestion> {
    dispatch({ type: Actions[Actions.UPDATE_SETTINGS_START] });
    const requestDescription = http.newRequestDescription();
    return http
        .put(requestDescription, `/api/questions/${question.id}`, question.asJson)
        .then(r => r.data)
        .then(q => Question.load(q))
        .then((q: IQuestion) => {
            dispatch({
                type: Actions[Actions.UPDATE_SETTINGS_FINISH],
                question: q,
            });
            refetchBridgeEntityTypes(queryClient);
            return q;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function deleteQuestion(
    questionId: QuestionId,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void | Question[]> {
    const requestDescription = http.newRequestDescription();
    return http
        .deleteReq(requestDescription, `/api/questions/${questionId}`)
        .then(() => {
            dispatch({
                type: Actions[Actions.DELETE_SETTING_FINISH],
                questionId,
            });
            refetchBridgeEntityTypes(queryClient);
            return null;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateCommunityFields(
    communityId: CommunityId,
    changes: Record<string, unknown>,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): void {
    updateCommunityFieldsPromise(communityId, changes, queryClient, dispatch);
}

export async function updateCommunityFieldsPromise(
    communityId: CommunityId,
    changes: Record<string, unknown>,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<Community | void> {
    dispatch({ type: Actions[Actions.UPDATE_COMMUNITY_START] });
    const requestDescription = http.newRequestDescription();
    return http
        .put(requestDescription, `/api/communities/${communityId}`, changes)
        .then(r => r.data)
        .then((responseData: Community) => {
            dispatch({
                type: Actions[Actions.UPDATE_COMMUNITY_FINISH],
                community: responseData,
            });
            invalidateCommunityQueryCache(queryClient);
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export async function updateCommunity(
    communityId: CommunityId,
    fieldName: string,
    newValue: unknown,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void> {
    await updateCommunityPromise(communityId, fieldName, newValue, queryClient, dispatch);
}

export async function updateCommunityPromise(
    communityId: CommunityId,
    fieldName: string,
    newValue: unknown,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void> {
    dispatch({ type: Actions[Actions.UPDATE_COMMUNITY_START] });
    const payload = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    (payload as any)[fieldName] = newValue ?? null;
    const result = await http.put(
        http.newRequestDescription(),
        `/api/communities/${communityId}`,
        payload,
    );
    const data = result.data;
    invalidateCommunityQueryCache(queryClient);
    dispatch({
        type: Actions[Actions.UPDATE_COMMUNITY_FINISH],
        community: data,
    });
}

export function updateClientMultiField(
    clientId: ClientId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    newValues: any,
    dispatch: Dispatch<Action>,
): void {
    updateClientMultiFieldPromise(clientId, newValues, dispatch);
}

export function updateClientMultiFieldPromise(
    clientId: ClientId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    newValues: any,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    const requestDescription = http.newRequestDescription();
    return http
        .put(requestDescription, `/api/clients/${clientId}`, newValues)
        .then(r => r.data)
        .then((responseData: Client) => {
            dispatch({
                type: Actions[Actions.UPDATE_CLIENT_FINISH],
                client: responseData,
                when: new Date(),
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function updateClientAnswer(
    clientId: ClientId,
    answer: IAnswer,
    dispatch: Dispatch<Action>,
): Promise<void | IAnswer> {
    dispatch({ type: Actions[Actions.UPDATE_CLIENT_START] });
    const requestDescription = http.newRequestDescription();
    return (
        http
            .put(requestDescription, `/api/clients/${clientId}/answers`, answer)
            .then(r => r.data)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((responseData: any) => {
                dispatch({
                    type: Actions[Actions.UPDATE_CLIENT_FINISH],
                    client: responseData,
                    when: new Date(),
                });
                return responseData;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((data: any) => Client.load(data))
            .then(c => c.answers.find(a => a.questionId === answer.questionId))
            .catch(r => http.errorHandler(r, requestDescription, dispatch))
    );
}

export function updateAdditionalClientAnswer(
    clientId: ClientId,
    additionalClientId: InternalId,
    answer: IAnswer,
    dispatch: Dispatch<Action>,
): Promise<void | IAnswer> {
    dispatch({ type: Actions[Actions.UPDATE_CLIENT_START] });
    const requestDescription = http.newRequestDescription();
    return (
        http
            .put(
                requestDescription,
                `/api/clients/${clientId}/additionalClients/${additionalClientId}/answers`,
                answer,
            )
            .then(r => r.data)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((responseData: any) => {
                dispatch({
                    type: Actions[Actions.UPDATE_CLIENT_FINISH],
                    client: responseData,
                    when: new Date(),
                });
                return responseData;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((data: any) => Client.load(data))
            .then(c =>
                c.additionalClient.answers.find(a => a.questionId === answer.questionId),
            )
            .catch(r => http.errorHandler(r, requestDescription, dispatch))
    );
}

export function updateCommunityAnswer(
    communityId: CommunityId,
    answer: IAnswer,
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void | IAnswer> {
    dispatch({ type: Actions[Actions.UPDATE_COMMUNITY_START] });
    const requestDescription = http.newRequestDescription();
    return (
        http
            .put(requestDescription, `/api/communities/${communityId}/answers`, answer)
            .then(r => r.data)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((responseData: any) => {
                dispatch({
                    type: Actions[Actions.UPDATE_COMMUNITY_FINISH],
                    community: responseData,
                });
                invalidateCommunityQueryCache(queryClient);
                return responseData;
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((data: any) => Community.load(data))
            .then(c => c.answers.find(a => a.questionId === answer.questionId))
            .catch(r => http.errorHandler(r, requestDescription, dispatch))
    );
}

export function updateClient(
    clientId: ClientId,
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    newValue: any,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    return updateClientPromise(clientId, fieldName, newValue, dispatch);
}

export function updateClientPromise(
    clientId: ClientId,
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    newValue: any,
    dispatch: Dispatch<Action>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    dispatch({ type: Actions[Actions.UPDATE_CLIENT_START] });
    const payload = {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    (payload as any)[fieldName] = newValue;
    return updateClientMultiFieldPromise(clientId, payload, dispatch);
}

export function loadCommunity(
    communityId: CommunityId,
    dispatch: Dispatch<Action>,
): void {
    dispatch({ type: Actions[Actions.LOAD_COMMUNITY_START] });
    const path = `/api/communities/${communityId}`;
    const requestDescription = http.newRequestDescription();
    http.get(requestDescription, path)
        .then(r => r.data)
        .then((responseData: Community) => {
            dispatch({
                type: Actions[Actions.LOAD_COMMUNITY_FINISH],
                community: responseData,
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function loadClient(clientId: ClientId, dispatch: Dispatch<Action>): void {
    dispatch({ type: Actions[Actions.LOAD_CLIENT_START], clientId });
    const path = `/api/clients/${clientId}`;
    const requestDescription = http.newRequestDescription();
    http.get(requestDescription, path)
        .then(r => r.data)
        .then((responseData: Client) => {
            dispatch({
                type: Actions[Actions.LOAD_CLIENT_FINISH],
                client: responseData,
                when: new Date(),
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

export function loadClientsForCommunity(
    dispatch: Dispatch<Action>,
    communityId: CommunityId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
): Promise<any> {
    return dispatch((async (d: Dispatch<Action>) => {
        d({ type: Actions[Actions.LOAD_CLIENTS_FOR_COMMUNITY_START] });
        const requestDescription = http.newRequestDescription();
        return http
            .get(requestDescription, `/api/communities/${communityId}/clients`)
            .then(r => r.data)
            .then((responseData: Client) => {
                d({
                    type: Actions[Actions.LOAD_CLIENTS_FOR_COMMUNITY_FINISH],
                    clients: responseData,
                });
                return responseData;
            })
            .catch(r => http.errorHandler(r, requestDescription, d));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    }) as any);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function loadSettings(dispatch: Dispatch<Action>): Promise<any> {
    dispatch({ type: Actions[Actions.LOAD_SETTINGS_START] });
    const requestDescription = http.newRequestDescription();
    return http
        .get(requestDescription, "/api/questions")
        .then(r => r.data)
        .then((responseData: IQuestion[]) => {
            const allSettings = responseData.map(rawQ => Question.load(rawQ));
            dispatch({
                type: Actions[Actions.LOAD_SETTINGS_FINISH],
                settings: allSettings,
            });
            return responseData;
        })
        .catch(r => http.errorHandler(r, requestDescription, dispatch));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function loadPermissions(dispatch: Dispatch<Action>): Promise<any> {
    return getAndDispatchActionsWithAPIPayload(
        dispatch,
        "GET",
        `/api/permissions`,
        Actions.LOAD_PERMISSIONS_START,
        Actions.LOAD_PERMISSIONS_FINISH,
        (responseData: PermissionMapping) => {
            setTimeout(() => {
                const userId = Maybe.fromValue(responseData)
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                    .map(x => (x as any).user)
                    .map(x => x.id)
                    .getOrElse("unknown");
                userIdEvent(parseEntityId<UserId>(userId));
                setErrorTrackingContext({ userId: userId });
            }, 1);
            const advancedMode = Maybe.fromValue(responseData)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                .map(x => (x as any).etc)
                .map(x => x.shouldUseAdvancedMode)
                .getOrElse(false);
            if (advancedMode) {
                browser.enabledAdvancedMode();
            }
            return { data: responseData };
        },
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export async function loadTenant(dispatch: Dispatch<Action>): Promise<any> {
    return await getAndDispatchActionsWithAPIPayload(
        dispatch,
        "GET",
        `/api/tenant`,
        Actions.LOAD_TENANT_START,
        Actions.LOAD_TENANT_FINISH,
        (responseData: ITenant) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            if ((responseData as any).hasFavicon) {
                setTimeout(() => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                    setFavicon(`/api/tenants/${(responseData as any).id}/favicon`);
                }, 1);
            }
            setTimeout(() => {
                const id = Maybe.fromValue(responseData)
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                    .map(x => (x as any).id)
                    .getOrElse(null);

                const parsedIdOrUnknown =
                    typeof id === "string" ? parseEntityId<TenantId>(id) : null;
                if (parsedIdOrUnknown) {
                    tenantIdEvent(parsedIdOrUnknown);
                }
                setErrorTrackingContext({ tenantId: parsedIdOrUnknown });
            }, 1);
            return { tenant: responseData };
        },
    );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function loadAllUsers(dispatch: Dispatch<Action>): Promise<any> {
    dispatch({ type: Actions[Actions.LOAD_ALL_USERS_START] });
    const requestDescription = http.newRequestDescription();
    return (
        http
            .get(requestDescription, "/api/users")
            .then(r => r.data)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .then((responseData: any[]) => {
                dispatch({
                    type: Actions[Actions.LOAD_ALL_USERS_END],
                    users: responseData,
                });
                return responseData;
            })
            .catch(r => http.errorHandler(r, requestDescription, dispatch))
    );
}

export function resetPassword(email: string, dispatch: Dispatch<Action>): void {
    dispatch({ type: Actions[Actions.RESET_PASSWORD_START] });
    http.post(http.newRequestDescription(), "/api/password/reset", { email })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        .then((responseData: any) => {
            responseData.data.ok
                ? dispatch({ type: Actions[Actions.RESET_PASSWORD_SUCCESS] })
                : dispatch({ type: Actions[Actions.RESET_PASSWORD_FAILED] });
        })
        .catch(err => {
            dispatch({ type: Actions[Actions.RESET_PASSWORD_FAILED] });
        });
}

export function setPassword(
    password: string,
    token: string,
    dispatch: Dispatch<Action>,
): void {
    dispatch({ type: Actions[Actions.SET_PASSWORD_START] });
    http.post(http.newRequestDescription(), "/api/password/set", { password, token })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        .then((responseData: any) => {
            responseData.data.ok
                ? dispatch({ type: Actions[Actions.SET_PASSWORD_SUCCESS] })
                : dispatch({ type: Actions[Actions.SET_PASSWORD_FAILED] });
        })
        .catch(err => {
            dispatch({ type: Actions[Actions.SET_PASSWORD_FAILED] });
        });
}

export async function loadAll(
    queryClient: QueryClient,
    dispatch: Dispatch<Action>,
): Promise<void> {
    queryClient.clear();
    const tenantPromise = loadTenant(dispatch);
    loadSettings(dispatch);
    await tenantPromise;
    loadAllUsers(dispatch);
    loadPermissions(dispatch);
}

export function startHeartbeats(dispatch: Dispatch<Action>): void {
    const heartbeat = async () => {
        try {
            const result = await http.get(
                http.newRequestDescription(),
                "/api/session/heartbeat",
            );
            const payload = {
                type: Actions[Actions.HEARTBEAT],
                now: new Date(),
                data: result.data,
            };
            dispatch(payload);
        } catch (e) {
            console.warn("Error connecting to Heartbeat API");
        }
    };
    setInterval(heartbeat, 1000 * 60 * 10);
    heartbeat();
}

export function startTicks(dispatch: Dispatch<Action>): void {
    dispatch({ type: Actions[Actions.TICK], now: new Date() });
    setInterval(() => {
        dispatch({ type: Actions[Actions.TICK], now: new Date() });
    }, 1000 * 120);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
export function selectContact(parentId: ContactEntityId, contactId: ContactId): any {
    return {
        type: Actions[Actions.SELECT_CONTACT],
        parentId,
        contactId,
    };
}

export function refetchReminders(queryClient: QueryClient) {
    if (queryClient.getQueryCache().find("getReminders")) {
        queryClient.invalidateQueries("getReminders", { exact: true });
    }
}

function refetchBridgeEntityTypes(queryClient: QueryClient) {
    queryClient
        .getQueryCache()
        .findAll(["getEntities"])
        .forEach(q => {
            q.fetch();
        });
}
