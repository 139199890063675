import { defaultTenantSettings, ITenantSettings } from "@sp-crm/core";
import {
    RegionSettingSource,
    SettingValue,
    SettingValueInput,
    useDeleteRegionSettingsMutation,
    useGetRegionSettingsQuery,
    useGetTenantSettingsQuery,
    usePutRegionSettingMutation,
    usePutRegionSettingsMutation,
    usePutTenantSettingMutation,
} from "generated/graphql";
import { useCallback, useEffect, useMemo } from "react";
import { stableQueryOptions } from "util/requests";
import { useCurrentTenant, useRegionId } from "./hooks";

type SettingValueType = string | boolean | number | null;

interface RegionSettingsHook {
    settings: ITenantSettings;
    status: "loading" | "error" | "success";
    putSetting: (key: keyof ITenantSettings, value: SettingValueType) => Promise<boolean>;
    putSettings: (
        settings: { key: keyof ITenantSettings; value: SettingValueType }[],
    ) => Promise<boolean>;
    atLeastOneOverridden: (keys: (keyof ITenantSettings)[]) => boolean;
    deleteSettings: (keys: (keyof ITenantSettings)[]) => Promise<boolean>;
}

const toJsonValue = (value: SettingValue): SettingValueType => {
    if (typeof value.textValue === "string") {
        return value.textValue;
    }

    if (typeof value.intValue === "number") {
        return value.intValue;
    }

    if (typeof value.booleanValue === "boolean") {
        return value.booleanValue;
    }

    return null;
};

const toApiValue = (value: SettingValueType): SettingValueInput => {
    if (typeof value === "string") {
        return { textValue: value };
    }

    if (typeof value === "number") {
        return { intValue: value };
    }

    if (typeof value === "boolean") {
        return { booleanValue: value };
    }

    return { textValue: null };
};

export const useRegionSettings = (): RegionSettingsHook => {
    const regionId = useRegionId();

    const getSettingsQuery = useGetRegionSettingsQuery(
        { params: { regionId } },
        { ...stableQueryOptions(), enabled: !!regionId, keepPreviousData: true },
    );

    const putSettingMutation = usePutRegionSettingMutation();
    const putSettingsMutation = usePutRegionSettingsMutation();
    const deleteSettingsMutation = useDeleteRegionSettingsMutation();

    const putSetting = useCallback(
        async (key: keyof ITenantSettings, value: SettingValueType) => {
            const result = await putSettingMutation.mutateAsync({
                params: {
                    regionId,
                    key: key,
                    value: toApiValue(value),
                },
            });
            getSettingsQuery.refetch();

            return result.putRegionSetting;
        },
        [putSettingMutation, getSettingsQuery, regionId],
    );

    const putSettings = useCallback(
        async (settings: { key: keyof ITenantSettings; value: SettingValueType }[]) => {
            const result = await putSettingsMutation.mutateAsync({
                params: {
                    regionId,
                    settings: settings.map(({ key, value }) => ({
                        key,
                        value: toApiValue(value),
                    })),
                },
            });
            getSettingsQuery.refetch();

            return result.putRegionSettings;
        },
        [putSettingsMutation, getSettingsQuery, regionId],
    );

    const atLeastOneOverridden = useCallback(
        (keys: (keyof ITenantSettings)[]) => {
            return keys.some(key =>
                getSettingsQuery.data?.getRegionSettings.settings.some(
                    setting =>
                        setting.key === key &&
                        setting.source === RegionSettingSource.Region,
                ),
            );
        },
        [getSettingsQuery.data],
    );

    const deleteSettings = useCallback(
        async (keys: (keyof ITenantSettings)[]) => {
            const result = await deleteSettingsMutation.mutateAsync({
                params: { regionId, keys },
            });
            getSettingsQuery.refetch();
            return result.deleteRegionSettings;
        },
        [getSettingsQuery, deleteSettingsMutation, regionId],
    );

    const status = useMemo(() => {
        if (getSettingsQuery.data) {
            return "success";
        }

        if (getSettingsQuery.error) {
            return "error";
        }

        return "loading";
    }, [getSettingsQuery.data, getSettingsQuery.error]);

    const settings: ITenantSettings = useMemo(() => {
        if (status === "success") {
            return getSettingsQuery.data.getRegionSettings.settings.reduce(
                (acc, setting) => ({ ...acc, [setting.key]: toJsonValue(setting.value) }),
                {} as ITenantSettings,
            );
        }

        return null;
    }, [status, getSettingsQuery.data]);

    return {
        settings,
        status,
        putSetting,
        putSettings,
        atLeastOneOverridden,
        deleteSettings,
    };
};

type TenantSettingsHook = {
    settings: ITenantSettings;
    status: "loading" | "error" | "success";
    putSetting: (key: keyof ITenantSettings, value: SettingValueType) => Promise<boolean>;
};

export const useTenantSettings = (): TenantSettingsHook => {
    const tenant = useCurrentTenant();

    const tenantId = tenant?.tenantId;

    const getSettingsQuery = useGetTenantSettingsQuery(
        {},
        { ...stableQueryOptions(), enabled: !!tenantId, keepPreviousData: !!tenantId },
    );

    useEffect(() => {
        if (tenantId) {
            getSettingsQuery.refetch();
        } else {
            getSettingsQuery.remove();
        }
    }, [tenantId]); // eslint-disable-line react-hooks/exhaustive-deps

    const putSettingMutation = usePutTenantSettingMutation();

    const putSetting = useCallback(
        async (key: keyof ITenantSettings, value: SettingValueType) => {
            const result = await putSettingMutation.mutateAsync({
                params: {
                    tenantId,
                    key: key,
                    value: toApiValue(value),
                },
            });
            getSettingsQuery.refetch();

            return result.putTenantSetting;
        },
        [putSettingMutation, getSettingsQuery, tenantId],
    );

    const status = useMemo(() => {
        if (getSettingsQuery.data) {
            return "success";
        }

        if (getSettingsQuery.error) {
            return "error";
        }

        return "loading";
    }, [getSettingsQuery.data, getSettingsQuery.error]);

    const settings: ITenantSettings = useMemo(() => {
        if (status === "success") {
            return getSettingsQuery.data.getTenantSettings.settings.reduce(
                (acc, setting) => ({ ...acc, [setting.key]: toJsonValue(setting.value) }),
                {} as ITenantSettings,
            );
        }

        return defaultTenantSettings();
    }, [status, getSettingsQuery.data]);

    return {
        settings,
        status,
        putSetting,
    };
};
