import { Maybe, UserPreferences } from "@sp-crm/core";
import { createSelector } from "reselect";
import { SingleUserState } from "../reducers/user";
import { UserState } from "../reducers/users";
import { getUsers, getUserState } from "./selectors";

/**
 * userPreferences returns the preferences for the currently logged in user
 */
export const userPreferences = createSelector(
    getUsers,
    getUserState,
    (users: UserState, singleUserState: SingleUserState): UserPreferences => {
        return Maybe.fromValue(singleUserState)
            .map(s => s.userId)
            .map(id => users.users[id])
            .map(u => u.preferences)
            .getOrElse(new UserPreferences());
    },
);
